import kds from './kds'

export default {
  kds,
  order: 'Order',
  orders: 'Orders',
  orderStatus: 'Order status',
  printTicket: 'Print ticket',
  email: 'E-mail',
  refund: 'Refund',
  receipt: 'Receipt',
  articles: 'Articles',
  information: 'Information',
  deliverFullOrder: 'Deliver full order',
  unDeliverFullOrder: 'Un-deliver full order',
  emptyRightNow: 'Empty right now.',
  time: 'Time',
  sortTime: 'Time',
  sortName: 'Name',
  delivery: 'Delivery',
  total: 'Total',
  orderCode: 'Order code',
  qty: 'QTY',
  of: 'of',
  undefined: 'Undefined',
  notDelivered: 'Not delivered',
  delivered: 'Delivered',
  confirm: {
    one: 'Confirm order',
    many: 'Confirm all new orders'
  },
  status: {
    started: 'Started',
    cancelled: 'Cancelled',
    success: 'Success',
    created: 'Created',
    waiting: 'Waiting for tip'
  },
  menu: {
    all: 'All',
    recent: 'Recent',
    done: 'Done',
    filter: 'Filter',
    sound: 'Sound',
    off: 'off',
    on: 'on',
    pos: 'POS',
    kiosk: 'Kiosk',
    online: 'Online',
    origin: 'Origin',
    preparation: 'Preparation',
    grid: 'Grid',
    layoutKds: 'KDS',
    list: 'List',
    listMini: 'Mini list',
    layout: 'Layout',
    kdsOrders: 'Show orders by default'
  },
  details: {
    dateCreated: 'Date created',
    dateModified: 'Date modified',
    paymentMethod: 'Payment method',
    pspId: 'PSP Trans.Id',
    orderCode: 'Order code',
    currencyRate: 'Currency rate',
    currencyCode: 'Currency code',
    paymentStatus: 'Payment status',
    deliveryStatus: 'Delivery status',
    note: 'Note',
    amount: 'Amount',
    orderNumber: 'Order number',
    delivery: {
      nothing: 'Nothing delivered',
      partially: 'Partially delivered',
      done: 'Done, All delivered'
    }
  }
}
