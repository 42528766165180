import kds from './kds'

export default {
  kds,
  order: 'Order',
  orders: 'Ordrar',
  orderStatus: 'Orderstatus',
  printTicket: 'Skriv ut bong',
  email: 'E-post',
  refund: 'Återbetalning',
  receipt: 'Kvittot',
  articles: 'Artiklar',
  information: 'Information',
  deliverFullOrder: 'Levererar hela ordern',
  unDeliverFullOrder: 'Avleverera hela ordern',
  emptyRightNow: 'Här va det tomt just nu.',
  time: 'Tid',
  sortTime: 'Time',
  sortName: 'Name',
  delivery: 'Leverans',
  total: 'Totalt',
  orderCode: 'Order kod',
  qty: 'Ant.',
  of: 'av',
  undefined: 'Odefinierad',
  notDelivered: 'Inte levererad',
  delivered: 'Levererad',
  confirm: {
    one: 'Bekräfta order',
    many: 'Bekräfta alla nya ordrar'
  },
  status: {
    started: 'Startad',
    cancelled: 'Avbruten',
    success: 'Slutförd',
    created: 'Skapad',
    waiting: 'Väntar på dricks'
  },
  menu: {
    all: 'Allt',
    recent: 'Senaste',
    done: 'Färdiga',
    filter: 'Filtrera',
    sound: 'Ljud',
    off: 'av',
    on: 'på',
    pos: 'POS',
    kiosk: 'Kiosk',
    online: 'Online',
    origin: 'Ursprung',
    preparation: 'Tillagning',
    grid: 'Rutnät',
    layoutKds: 'KDS',
    list: 'Lista',
    listMini: 'Minilista',
    layout: 'Utseende',
    kdsOrders: 'Visa ordrar som standard'
  },
  details: {
    dateCreated: 'Datum skapad',
    dateModified: 'Datum modifierad',
    paymentMethod: 'Betalsätt',
    pspId: 'PSP Trans.Id',
    orderCode: 'Order kod',
    currencyRate: 'Valutakurs',
    currencyCode: 'Valutakod',
    paymentStatus: 'Betalnings status',
    deliveryStatus: 'Leveransstatus',
    note: 'Notering',
    amount: 'Summa',
    orderNumber: 'Order nummer',
    delivery: {
      nothing: 'Inget levererat',
      partially: 'Delvis levererad',
      done: 'Färdig, Allt levererat'
    }
  }
}
